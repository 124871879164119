import React from "react";
import gsap from "gsap";
import "./loader.css";

const { useEffect } = React;

const IconLoader = ({ loadingMessage }) => {
  useEffect(() => {
    const tl = gsap.timeline();
    gsap.to("#voice1", { opacity: 1, duration: 0.8 });
    tl.to("#voice2", {
      opacity: 1,
      duration: 0.6,
      ease: "back.out(1.7)",
      delay: 0.5,
    });
    tl.to("#voice3", { opacity: 1, duration: 0.6, ease: "back.out(1.7)" });
    tl.repeat(-1);
    tl.yoyo(true);
  }, []);

  return (
    <div className="loader-container">
      <svg
        width="120px"
        height="120px"
        viewBox="0 0 4742 4388"
        version="1.1"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinejoin: "round",
          strokeMiterlimit: "2",
        }}
      >
        <rect
          x="0"
          y="0"
          width="4741.63"
          height="4387.37"
          style={{ fill: "none" }}
        />
        <path
          id="voice3"
          d="M2690.7,2150.78c-0,364.542 -90.75,719.5 -262.5,1026.5c-38.059,68.133 -80.175,133.917 -126.125,197c-24.054,33.075 -59.492,56.083 -99.496,64.592c-40.004,8.508 -81.738,1.912 -117.171,-18.509l-2.208,-1.291c-20.129,-11.667 -37.571,-27.442 -51.196,-46.296c-13.625,-18.854 -23.125,-40.371 -27.883,-63.142c-4.755,-22.771 -4.663,-46.292 0.275,-69.021c4.941,-22.733 14.612,-44.171 28.387,-62.916c38.021,-52.242 72.875,-106.717 104.375,-163.125c143.958,-257.417 220.042,-556.084 220.042,-863.792c-0,-300 -72.542,-592.292 -209.875,-845.333c-36.05,-66.563 -76.75,-130.5 -121.792,-191.335c-13.779,-18.736 -23.442,-40.171 -28.362,-62.902c-4.921,-22.73 -4.98,-46.243 -0.184,-68.999c4.8,-22.757 14.354,-44.242 28.034,-63.05c13.675,-18.809 31.175,-34.515 51.345,-46.091l2.209,-1.292c35.212,-20.318 76.671,-26.965 116.471,-18.673c39.795,8.292 75.154,30.942 99.32,63.632c54,73.086 102.83,149.856 146.125,229.75c163.709,301.71 250.209,648.96 250.209,1004.29Z"
          style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
        />

        <path
          id="voice2"
          d="M1938.16,2153.61c-0,225.792 -56.334,445.838 -162.875,636.379c-36.688,65.705 -79.459,127.825 -127.75,185.542c-25.009,29.65 -59.663,49.55 -97.879,56.208c-38.217,6.663 -77.555,-0.345 -111.121,-19.791l-2.25,-1.292c-20.909,-12.1 -38.921,-28.629 -52.763,-48.429c-13.841,-19.8 -23.179,-42.388 -27.362,-66.179c-4.179,-23.796 -3.1,-48.217 3.162,-71.546c6.259,-23.333 17.554,-45.013 33.088,-63.513c34.766,-41.654 65.554,-86.475 91.958,-133.875c78.792,-140.916 120.458,-304.708 120.458,-473.666c0,-164.709 -39.75,-325 -114.916,-463.542c-30.063,-55.654 -66.142,-107.842 -107.584,-155.625c-68.708,-78.917 -49.5,-200.583 41.125,-252.917l2.375,-1.375c33.188,-19.123 71.988,-26.124 109.767,-19.808c37.779,6.317 72.192,25.554 97.358,54.433c193,220.834 305.209,520.038 305.209,838.996Z"
          style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
        />
        <path
          id="voice1"
          d="M970.703,2701.9c-41.304,0.029 -81.912,-10.637 -117.875,-30.954l-2.166,-1.254l-471.542,-272.25c-43.938,-25.367 -80.424,-61.854 -105.791,-105.796c-25.367,-43.937 -38.721,-93.779 -38.721,-144.517c-0,-50.733 13.354,-100.575 38.721,-144.512c25.367,-43.942 61.853,-80.429 105.791,-105.8l471.792,-272.375c7.75,-4.458 15.75,-8.583 23.75,-12.292c62.041,-28.708 129.166,-29.166 188.708,-1.791c85.042,39.25 146.5,130.125 187.917,277.875c62.5,222.75 39.416,463.666 -64.709,678.375c-19.645,40.55 -50.312,74.75 -88.491,98.675c-38.179,23.929 -82.325,36.616 -127.384,36.616Zm-441.666,-554.75l417.416,240.917c52.709,-135.875 61.709,-286.542 23.959,-421.25c-5.729,-21.275 -12.842,-42.15 -21.292,-62.5l-420.083,242.833Z"
          style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
        />
      </svg>

      <p>{loadingMessage}</p>
    </div>
  );
};

const IconLoaderVariant1 = () => {
  useEffect(() => {
    const tl = gsap.timeline();
    gsap.to("#voice1", { opacity: 1, duration: 0.8 });
    tl.to("#voice2", {
      opacity: 1,
      duration: 0.6,
      ease: "back.out(1.7)",
      delay: 0.5,
    });
    tl.to("#voice3", { opacity: 1, duration: 0.6, ease: "back.out(1.7)" });
    tl.repeat(-1);
    tl.yoyo(true);
  }, []);

  return (
    <svg
      id="logo-container"
      width="80"
      height="80"
      viewBox="0 0 4742 4388"
      version="1.1"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: "2",
      }}
    >
      <rect
        x="100"
        y="100"
        width="4741.63"
        height="4387.37"
        style={{ fill: "none" }}
      />
      <path
        id="voice3"
        d="M2690.7,2150.78c-0,364.542 -90.75,719.5 -262.5,1026.5c-38.059,68.133 -80.175,133.917 -126.125,197c-24.054,33.075 -59.492,56.083 -99.496,64.592c-40.004,8.508 -81.738,1.912 -117.171,-18.509l-2.208,-1.291c-20.129,-11.667 -37.571,-27.442 -51.196,-46.296c-13.625,-18.854 -23.125,-40.371 -27.883,-63.142c-4.755,-22.771 -4.663,-46.292 0.275,-69.021c4.941,-22.733 14.612,-44.171 28.387,-62.916c38.021,-52.242 72.875,-106.717 104.375,-163.125c143.958,-257.417 220.042,-556.084 220.042,-863.792c-0,-300 -72.542,-592.292 -209.875,-845.333c-36.05,-66.563 -76.75,-130.5 -121.792,-191.335c-13.779,-18.736 -23.442,-40.171 -28.362,-62.902c-4.921,-22.73 -4.98,-46.243 -0.184,-68.999c4.8,-22.757 14.354,-44.242 28.034,-63.05c13.675,-18.809 31.175,-34.515 51.345,-46.091l2.209,-1.292c35.212,-20.318 76.671,-26.965 116.471,-18.673c39.795,8.292 75.154,30.942 99.32,63.632c54,73.086 102.83,149.856 146.125,229.75c163.709,301.71 250.209,648.96 250.209,1004.29Z"
        style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
      />

      <path
        id="voice2"
        d="M1938.16,2153.61c-0,225.792 -56.334,445.838 -162.875,636.379c-36.688,65.705 -79.459,127.825 -127.75,185.542c-25.009,29.65 -59.663,49.55 -97.879,56.208c-38.217,6.663 -77.555,-0.345 -111.121,-19.791l-2.25,-1.292c-20.909,-12.1 -38.921,-28.629 -52.763,-48.429c-13.841,-19.8 -23.179,-42.388 -27.362,-66.179c-4.179,-23.796 -3.1,-48.217 3.162,-71.546c6.259,-23.333 17.554,-45.013 33.088,-63.513c34.766,-41.654 65.554,-86.475 91.958,-133.875c78.792,-140.916 120.458,-304.708 120.458,-473.666c0,-164.709 -39.75,-325 -114.916,-463.542c-30.063,-55.654 -66.142,-107.842 -107.584,-155.625c-68.708,-78.917 -49.5,-200.583 41.125,-252.917l2.375,-1.375c33.188,-19.123 71.988,-26.124 109.767,-19.808c37.779,6.317 72.192,25.554 97.358,54.433c193,220.834 305.209,520.038 305.209,838.996Z"
        style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
      />
      <path
        id="voice1"
        d="M970.703,2701.9c-41.304,0.029 -81.912,-10.637 -117.875,-30.954l-2.166,-1.254l-471.542,-272.25c-43.938,-25.367 -80.424,-61.854 -105.791,-105.796c-25.367,-43.937 -38.721,-93.779 -38.721,-144.517c-0,-50.733 13.354,-100.575 38.721,-144.512c25.367,-43.942 61.853,-80.429 105.791,-105.8l471.792,-272.375c7.75,-4.458 15.75,-8.583 23.75,-12.292c62.041,-28.708 129.166,-29.166 188.708,-1.791c85.042,39.25 146.5,130.125 187.917,277.875c62.5,222.75 39.416,463.666 -64.709,678.375c-19.645,40.55 -50.312,74.75 -88.491,98.675c-38.179,23.929 -82.325,36.616 -127.384,36.616Zm-441.666,-554.75l417.416,240.917c52.709,-135.875 61.709,-286.542 23.959,-421.25c-5.729,-21.275 -12.842,-42.15 -21.292,-62.5l-420.083,242.833Z"
        style={{ fill: "#e2514c", fillRule: "nonzero", opacity: "0" }}
      />
    </svg>
  );
};

export { IconLoader, IconLoaderVariant1 };
