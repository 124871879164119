let baseUrl = "https://api.press-play.xyz";

if (window.location.hostname === "localhost") {
  baseUrl = "http://localhost:4000";
}else {
  baseUrl = "https://api.press-play.xyz";

}

export const apiUrl = `${baseUrl}/api/users`;
export const activationUrl = `${baseUrl}/api/users/activate-account`;
export const podcastUrl = `${baseUrl}/api/podcasts`;
export const episodeUrl = `${baseUrl}/api/episodes`;
export const likeAndUnlike = `${baseUrl}/api/users`;
